@media screen and (min-width: 1px) and (max-width: 1064px) {
  .mainPage__container {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column-reverse;
  }

  .mainPage__mainLogo {
    width: 80px;
    height: 51px;
    cursor: pointer;
    position: absolute;
    top: 9px;
    left: 20px;
  }
  .mainPage__leftPart {
    flex: 1;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainPage__rightPart {
    width: 100%;
    flex-shrink: 0;
    flex: 1;
    background-color: #babfc2;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    min-height: 76svh;
    animation: slidingLeft 1s ease 0s 1 forwards;
    margin-top: 185px;
  }
  .mainPage__titles {
    position: absolute;
    top: 112px;
    /* left: 24px; */
    width: calc(100% - 48px);
    animation: slidingLeftTitles 2s ease 0s 1 forwards;
    opacity: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 56px;
  }
  .mainPage__title {
    width: 100%;
  }
  .mainPage__subtitle {
    bottom: 0;
    width: 73vw;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px; /* 112.5% */
  }
  .mainPage__listIcon {
    width: 20px;
    height: 20px;
    background: url("../../Media/iconFilm.svg") center no-repeat;
  }
  .mainPage__listElement {
    height: 44px;
    padding: 12px 0;
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px; /* 106.667% */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .mainPage__listElement:first-of-type {
    margin-top: 20px;
  }
  .mainPage__image {
    position: absolute;
    z-index: 3;
    bottom: 0;
    animation: slidingLeftAbsolute 1.5s ease 0s 1 forwards;
    max-height: 80%;
    max-width: none;
    /* width: 100%; */
    max-width: 100%;
    object-fit: contain;
  }
  .mainPage__links {
    display: none;
    gap: 28px;
    animation: slidingDown 1s ease 2s 1 forwards;
    opacity: 0;
  }
  .mainPage__linkElement {
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    align-items: center;
  }
  .mainPage__logos {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 22.6px;
    left: 0px;
    flex-wrap: wrap;
    gap: 22.6px;
    z-index: 3;
    justify-content: space-between;
    animation: slidingUp 1s ease 1.5s 1 forwards;
    opacity: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .mainPage__logos > * {
    object-fit: contain;
    max-width: calc(25% - 11.3px);
  }
  .mainPage__zones {
    animation: slidingUp 1s ease 0s 1 forwards;
    opacity: 0;
    padding: 24px 20px 200px;
  }

  @keyframes slidingLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slidingLeftAbsolute {
    0% {
      right: -100%;
    }
    100% {
      right: -11px;
    }
  }
  @keyframes slidingLeftTitles {
    0% {
      right: -100%;
      opacity: 1;
    }
    100% {
      right: 24px;
      opacity: 1;
    }
  }
  @keyframes slidingUp {
    0% {
      transform: translateY(100%);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slidingDown {
    0% {
      transform: translateY(-100%);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
