@media screen and (min-width: 1px) and (max-width: 1064px) {
  .about__logos {
    display: flex;
    flex-wrap: wrap;
  }
  .about__logo {
    width: calc(50% - 1px);
    height: 88px;
    object-fit: contain;
  }
  .about__logoDivider {
    display: none;
    width: 0;
    background-color: none;
  }
  .about__logo:nth-of-type(1),
  .about__logo:nth-of-type(3),
  .about__logo:nth-of-type(5),
  .about__logo:nth-of-type(7) {
    border-right: 1px solid rgba(31, 31, 31, 0.2);
  }
  .about__logo:nth-of-type(1),
  .about__logo:nth-of-type(2),
  .about__logo:nth-of-type(3),
  .about__logo:nth-of-type(4),
  .about__logo:nth-of-type(5),
  .about__logo:nth-of-type(6) {
    border-bottom: 1px solid rgba(31, 31, 31, 0.2);
  }
  .about__text {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 120% */
  }
  .about__title {
    width: 100%;
    padding: 24px 0 20px;
  }
  .about__quoteBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 78px;
    row-gap: 24px;
    padding-bottom: 0 !important;
  }
  .about__quoteTitle {
    color: #8f8f8f;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px; /* 120% */
    text-transform: uppercase;
    order: 1;
  }
  .about__quote {
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px; /* 127.273% */
    order: 2;
  }
  .author__container {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid #c3c3c3;
  order: 3;
  }
  .author__photo {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
    object-fit: contain;
  }
  .author__job{
  color: #8F8F8F;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  }
}
