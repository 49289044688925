@media screen and (min-width: 1px) and (max-width: 1064px) {
  .contacts__image {
    width: 100%;
  }
  .contacts__info {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .contacts__infoBlock {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 0 40px;
    flex-direction: column;
  }
  .contacts__capture {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 150% */
    text-transform: uppercase;
    width: 104px;
    opacity: 0.5;
    flex-shrink: 0;
    text-wrap: wrap;
  }
  .small{
    font-size: 12px;
    display: block;
  }
  .contacts__description {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; /* 127.273% */
  }
  .d_flex_row {
    flex-direction: row !important;
    gap: 10px;
  }
  .text_nowrap {
    width: max-content !important;
    text-wrap: nowrap;
  }
  /* .contacts__media{
    display: flex;

  } */
  .contacts__logo {
    width: 66px;
    object-fit: contain;
    margin-right: 8px;
  }

}
