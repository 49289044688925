@media screen and (min-width: 1px) and (max-width: 1064px) {
  .popup__background {
    position: fixed;
    inset: 0;
    background-color: rgba(31, 31, 31, 0.8);
    z-index: 3;
  }
  .popup__container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    margin-left: auto;
    padding: 36px 20px;
    box-sizing: border-box;
    animation: slidingLeft 2s ease 0s 1 forwards;
    opacity: 0;
  }
  .animated {
    animation: slidingLeft 2s ease 0s 1 forwards;
    opacity: 0;
    background-color: #fff;
  }
  .popup__container_front {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    margin-left: auto;
    padding: 36px 20px;
    box-sizing: border-box;
    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;
    animation: slidingLeft 2s ease 0s 1 forwards;
    opacity: 0;
  }
}
