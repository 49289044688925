@media screen and (min-width: 1px) and (max-width: 1064px) {
  .zones__container {
    padding: 11px 20px 64px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  .zones__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .zones__headerNav {
    width: 49.4%;
    flex-shrink: 0;
  }
  .zones__title {
    position: absolute;
    top: 112px;
    width: calc(100% - 50px);
    animation: slidingLeftTitles 2s ease 0s 1 forwards;
    opacity: 0;
  }
  .zones__navBar {
    padding-top: 140px;
    padding-right: 0;
    animation: slidingUp 2s ease 0.7s 1 forwards;
    opacity: 0;
    background: none;
  }
  .zones__linkElement {
    display: flex;
    gap: 12px;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    text-transform: uppercase;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 76px;
    cursor: pointer;
  }
  @keyframes slidingLeftTitles {
    0% {
      right: -100%;
      opacity: 1;
    }
    100% {
      right: 24px;
      opacity: 1;
    }
  }
  @keyframes slidingUp {
    0% {
      transform: translateY(100%);
      opacity: 1;
      /* background: url('../../Media/bg.png') 2000px bottom / 60% no-repeat; */
    }
    100% {
      transform: translateY(0);
      opacity: 1;
      /* background: url('../../Media/bg.png') bottom right / 60% no-repeat; */
    }
  }
  @keyframes slidingImage {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
