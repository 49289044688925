.contacts__image {
  width: 100%;
}
.contacts__info {
  padding-top: 60px;
  padding-bottom: 60px;
}
.contacts__infoBlock {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0 40px;
}
.contacts__capture {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  text-transform: uppercase;
  width: 45%;
  opacity: 0.5;
  flex-shrink: 0;
  text-wrap: nowrap;
}
.contacts__description {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
}
.contacts__logo {
  width: 97.5px;
  object-fit: contain;
  margin-right: 12px;
}