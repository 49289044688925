.menu {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 22px;
  background: url("../../Media/burgerMenu.png") center no-repeat;
}
.menu__container {
  position: fixed;
  inset: 0;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  animation: slidingLeft 2s ease 0s 1 forwards;
}
.menu__title {
  width: calc(100% - 40px);
  margin-top: 70px;
}
.menu__closeBtn {
  /* margin: 22px 20px 0 0; */
  width: 24px;
  height: 24px;
  background: url("../../Media/closeBtn.svg") center no-repeat;
  align-self: flex-end;
  position: absolute;
  right: 20px;
  top: 22px;
}
.menu__links {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 70px;
  box-sizing: border-box;
}
.menu__linkElement {
  height: 76px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  text-transform: uppercase;
  align-items: center;
}
@keyframes slidingLeft {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
