.popup__background {
  position: fixed;
  inset: 0;
  background-color: rgba(31, 31, 31, 0.8);
  z-index: 3;
}
.popup__container {
  width: 1064px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  margin-left: auto;
  padding: 36px 32px;
  box-sizing: border-box;
  animation: slidingLeft 2s ease 0s 1 forwards;
  opacity: 0;
}
.popup__container::-webkit-scrollbar,
.animated::-webkit-scrollbar,
.popup__container_front::-webkit-scrollbar {
  width: 0;
  display: none;
}
.animated {
  animation: slidingLeft 2s ease 0s 1 forwards;
  opacity: 0;
  background-color: #fff;
}
.popup__container_front {
  width: 1064px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  margin-left: auto;
  padding: 36px 32px;
  box-sizing: border-box;
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  animation: slidingLeft 2s ease 0s 1 forwards;
  opacity: 0;
}
.popup__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
.popup__icon {
  background: url("../../Media/iconFilm.svg") center / 40px 40px no-repeat;
  width: 40px;
  height: 40px;
}
.popup__closeBtn {
  display: flex;
  height: 48px;
  width: 120px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid rgba(31, 31, 31);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: transparent;
  opacity: 0.5;
  cursor: pointer;
  box-sizing: border-box;
}
.popup__closeBtn:hover {
  opacity: 1;
  color: #fd4f57;
  border: 1px solid #fd4f57;
}
.popup__btns {
  display: flex;
  gap: 8px;
  animation: slidingLeft 1s ease 0s 1 forwards;
  opacity: 0;
}
.popup__btn {
  width: 48px;
  height: 48px;
  background: url("../../Media/arrowToRight.svg") center center no-repeat,
    #1f1f1f;
  transition: all 0.3s ease;
  cursor: pointer;
}
.popup__btn:hover {
  background-color: #fd4f57;
}
.rotated_btn {
  transform: rotate(180deg);
}

@keyframes slidingLeft {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
