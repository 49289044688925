.app {
  /* display: flex; */
  /* height: 100vh; */
  /* width: 100vw; */
  overflow: hidden;
}
.app::-webkit-scrollbar {
width: 0;
display: none;
}
h1 {
  font-size: 84px;
  font-weight: 700;
  line-height: 84px; /* 100% */
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
}
h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
h5{
font-size: 40px;
font-weight: 700;
line-height: 44px; /* 110% */
text-transform: uppercase;
margin: 0;
}
h6{
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 107.143% */
text-transform: uppercase;
margin: 0;
}
ul {
  /* list-style-type: none; */
  /* list-style-image: url("../../Media/iconFilm.svg"); */
  /* list-style-position: inside; */
  padding-inline-start: 0px;
  margin: 0;
}
ul[title]::before {
  content: attr(title);
  color: var(--black, #1f1f1f);
  opacity: 0.3;
  /* link */
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  margin-bottom: 20px;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
p{
  margin: 0;
}
.margin_left_auto {
  margin-left: auto;
}
.active_hover,
.active_hover > .active_hover_icon,
.active_hover > * > .active_hover_icon {
  transition: all 0.3s ease;
  cursor: pointer;
}
a.active,
.active_hover:hover {
  /* filter: invert(45%) sepia(74%) saturate(2167%) hue-rotate(327deg) brightness(98%) contrast(88%); */
  color: #fd4f57 !important;
}
a.active > * > .active_hover_icon,
a.active > .active_hover_icon,
.active_hover:hover > * > .active_hover_icon,
.active_hover:hover > .active_hover_icon {
  background: url("../../Media/iconFilmRed.svg") center no-repeat;
}
.divider {
  width: 100%;
  height: 8px;
  background: url("../../Media/Divider.svg") center / contain no-repeat;
}
.accented_text{
  color: rgba(253, 79, 87, 1) !important; 
}
.pad_bot_60{
  padding-bottom: 60px !important;
}
.pad_top_60{
  padding-top: 60px !important;
}
.pad_top_48{
  padding-top: 48px;
}
.pad_top_64{
  padding-top: 64px;
}
.pad_top_36{
  padding-top: 36px;
}
.pad_top_24{
  padding-top: 24px;
}
.pad_bot_24{
  padding-bottom: 24px;
}
.pad_bot_48{
  padding-bottom: 48px;
}
.mar_bot_28{
  margin-bottom: 28px;
}
.underlined{
  text-decoration: underline;
}