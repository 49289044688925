.about__logos {
  display: flex;
  justify-content: space-between;
}
.about__logo {
  width: 104px;
  height: 88px;
}
.about__logoDivider {
  width: 1px;
  background-color: rgba(31, 31, 31, 0.2);
}
.about__text {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}
.about__title {
  width: 783px;
  padding: 60px 0 20px;
}
.about__quoteBlock {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 78px; */
  row-gap: 60px;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.about__quoteTitle {
  color: #8f8f8f;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px; /* 120% */
  text-transform: uppercase;
}
.about__quote {
  width: 608px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
}
.author__container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 608px;
  padding-top: 24px;
  border-top: 1px solid #c3c3c3;
}
.author__photo {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
  object-fit: contain;
}
.author__name {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.author__job {
  color: #8f8f8f;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
}
