@import url(./Vendor/Fonts/brutal/stylesheet.css);
@import url(./Vendor/Animation/uikit/dist/css/uikit.css);
body {
  margin: 0;
  font-family: "Brutal Type", "Arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #1F1F1F;
  /* overflow: hidden; */
  /* height: 100vh; */
}
