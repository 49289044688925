.zones__container {
  padding: 11px 25px 40px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: url('../../Media/bg.png') bottom right / 60% no-repeat; */
}
.zones__container::-webkit-scrollbar {
  width: 0;
  display: none;
}
.zones__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zones__headerNav {
  width: 49.4%;
  flex-shrink: 0;
}
.zones__title {
  /* position: relative; */
  margin: 50px auto 0;
  top: 112px;
  width: calc(100% - 50px);
  animation: slidingLeftTitle 2s ease 0s 1 forwards;
  opacity: 0;
  z-index: 2;
}
.zones__navBar {
  padding-top: 32px;
  padding-right: 50px;
  animation: slidingUp 2s ease 0.7s 1 forwards;
  opacity: 0;
  /* background: url("../../Media/bg.png") 70% bottom / 947px 584px no-repeat; */
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: space-between;
}
.zones__map{
object-fit: contain;
width: 60%;
max-height: 584px;
}
.zones__ul{
  width: 40%;
}
.zones__linkElement {
  display: flex;
  gap: 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  text-transform: uppercase;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 67px;
  cursor: pointer;
}
@keyframes slidingLeftTitle {
  0% {
transform: translate(100%);
    opacity: 1;
  }
  100% {
    transform: translate(0);

    opacity: 1;
  }
}
@keyframes slidingUp {
  0% {
    transform: translateY(100%);
    opacity: 1;
    /* background: url('../../Media/bg.png') 2000px bottom / 60% no-repeat; */
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    /* background: url('../../Media/bg.png') bottom right / 60% no-repeat; */
  }
}
@keyframes slidingImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
