.zone__title {
  font-size: 74px;
  font-weight: 800;
  line-height: 84px; /* 113.514% */
  text-transform: uppercase;
}
.zone__infoBtns {
  display: flex;
  width: 100%;
  gap: 2px;
}
.zone__infoBtn {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  width: 50%;
  background: url("../../Media/iconFilmGrey.svg") 20px center no-repeat;
  height: 52px;
  padding: 16px 20px 16px 48px;
  box-sizing: border-box;
  opacity: 1;
  color: #8f8f8f;
transition: all .3s ease;
}
.zone__infoBtn:hover,
.zone__infoBtn_active {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  width: 50%;
  background: url("../../Media/iconFilmWhite.svg") 20px center no-repeat,
    #fd4f57;
  height: 52px;
  padding: 16px 20px 16px 48px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  opacity: 1;
}
.zone__photo {
  width: 100%;
  height: 562px;
  object-fit: contain;
}
.zone__subtitle {
  font-size: 40px;
  font-weight: 800;
  line-height: 44px; /* 110% */
  text-transform: uppercase;
}
.zone__description {
  font-size: 24px;
  font-weight: 800;
  line-height: 28px; /* 116.667% */
}
.zone__contentBlock {
  display: flex;
  justify-content: space-between;
  gap: 78px;
}
.zone__contentTitle {
  font-size: 28px;
  font-weight: 800;
  line-height: 40px; /* 100% */
  text-transform: uppercase;
  width: 368px;
  flex-shrink: 0;
}
.zone__contentText{
font-size: 22px;
font-weight: 500;
line-height: 28px; /* 127.273% */
}
.zone__photoBlock {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 78px; */
  row-gap: 60px;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.zone__photoContainer {
  width: 55%;
}
.zone__photoImg {
  /* width: 366px; */
  /* height: 442px; */
  object-fit: contain;
  max-width: 100%;
  max-height: 442px;
}
.zone__photoTitle {
  /* width: 352px; */
  padding-right: 190px;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  background: url("../../Media/stroke.svg") right center no-repeat;
}
.zone__photoDescription {
  width: 55%;
}
.zone__nextZones {
  display: flex;
}
.zone__nextCards {
  width: 100%;
  border-top: 1px solid rgba(31, 31, 31, 0.2);
}
.zone__nextCard {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding: 68px 20px 105px;
  border-right: 1px solid rgba(31, 31, 31, 0.2);
  cursor: pointer;
  border-top: 1px solid rgba(31, 31, 31, 0.2);
  justify-content: space-between;
}
/* .zone__nextCard:last {

} */
.zone__nextCard:last-child {
  border-right: none;
}
.zone__nextCardTitle {
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  width: 415px;
  /* height: 80px; */
  text-wrap: wrap;
}

.zone__nextCardImg {
  width: 460px;
  height: 320px;
  object-fit: cover;
}
