.mainPage__container {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.mainPage__container::-webkit-scrollbar {
  width: 0;
  display: none;
}
.mainPage__mainLogo {
  width: 80px;
  height: 51px;
  cursor: pointer;
}
.mainPage__leftPart {
  flex: 1;
  padding: 11px 16px 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mainPage__rightPart {
  width: 49.4%;
  flex-shrink: 0;
  flex: 1;
  background-color: #babfc2;
  padding: 11px 24px 24px 0;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  animation: slidingLeft 1s ease 0s 1 forwards;
}
.mainPage__titles {
  position: absolute;
  top: 112px;
  /* left: 24px; */
  width: calc(100% - 48px);
  animation: slidingLeftTitles 2s ease 0.7s 1 forwards;
  opacity: 0;
  z-index: 1;
}
.mainPage__title {
  width: 100%;
}
.mainPage__subtitle {
  bottom: 0;
  width: 38%;
  margin-top: 8px;
}
.mainPage__listIcon {
  width: 20px;
  height: 20px;
  background: url("../../Media/iconFilm.svg") center no-repeat;
}
.mainPage__listElement {
  height: 44px;
  padding: 12px 0;
  font-family: Arial;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px; /* 106.667% */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.mainPage__listElement:first-of-type {
  margin-top: 20px;
}
.mainPage__image {
  position: absolute;
  z-index: 3;
  bottom: 0;
  animation: slidingLeftAbsolute 1.5s ease 0s 1 forwards;
  max-height: 80%;
  max-width: 49.4%;
  object-fit: contain;
  pointer-events: none;
}
.mainPage__links {
  display: flex;
  gap: 28px;
  animation: slidingDown 1s ease 2s 1 forwards;
  opacity: 0;
  position: relative;
  z-index: 3;
}
.mainPage__linkElement {
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  align-items: center;
}
.mainPage__logos {
  display: flex;
  width: 12.4vw;
  position: absolute;
  bottom: 26px;
  left: 28px;
  flex-wrap: wrap;
  gap: 15px;
  z-index: 3;
  animation: slidingUp 1s ease 1.5s 1 forwards;
  opacity: 0;
}
.mainPage__zones {
  animation: slidingUp 1s ease 2s 1 forwards;
  opacity: 0;
  padding-top: 396px;
}
.mainPage__logo{
  object-fit: contain;

}

@keyframes slidingLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slidingLeftAbsolute {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
@keyframes slidingLeftTitles {
  0% {
    right: -100%;
    opacity: 1;
  }
  100% {
    right: 24px;
    opacity: 1;
  }
}
@keyframes slidingUp {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slidingDown {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
