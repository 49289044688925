@media screen and (min-width: 1px) and (max-width: 1064px) {
  .zone__title {
    font-size: 40px;
    line-height: 40px;
  }
  .zone__title_40 {
    font-size: 40px !important;
  }
  .zone__infoBtns {
    display: flex;
    width: 100%;
    gap: 2px;
    flex-wrap: wrap;
  }
  .zone__infoBtn {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    width: 100%;
    background: url("../../Media/iconFilmGrey.svg") 20px center no-repeat;
    height: 52px;
    padding: 16px 20px 16px 48px;
    box-sizing: border-box;
    color: #8f8f8f;
    opacity: 0;
    animation: slidingUpBtns 1s ease 0s 1 forwards;

  }
  .zone__infoBtn:hover,
  .zone__infoBtn_active {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    width: 100%;
    background: url("../../Media/iconFilmWhite.svg") 20px center no-repeat,
      #fd4f57;
    height: 52px;
    padding: 16px 20px 16px 48px;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
    opacity: 0;
    animation: slidingUpBtns 1s ease 0s 1 forwards;

  }
  .zone__photo {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .zone__subtitle {
    font-size: 24px;
    line-height: 32px; /* 110% */
  }
  .zone__description {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px; /* 116.667% */
  }
  .zone__contentBlock {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  .zone__contentTitle {
    font-size: 28px;
    line-height: 30px; /* 100% */
    width: 100%;
  }
  .zone__contentText {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px; /* 127.273% */
  }
  .zone__photoBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 78px;
    row-gap: 12px;
    padding-bottom: 0 !important;
  }
  .pad_bot_mobile_24 {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .zone__photoContainer {
    width: 100%;
  }
  .zone__photoImg {
    object-fit: contain;
    width: 100%;
    max-height: none;
  }
  .zone__photoTitle {
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px; /* 142.857% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    background: none;
    gap: 20px;
    text-wrap: nowrap;
    flex-wrap: nowrap;
    /* flex-shrink: 0; */
    padding-right: 0;
    /* padding-left: 65%; */
  }
  .zone__photoTitle_decoration {
    min-width: 40%;
    max-width: 80%;
    width: 40%;
    height: 1px;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: rgba(31, 31, 31, 0.2);
  }
  /* .zone__photoTitle::before {
    content: "";
    width: 80%;
    height: 1px;
    flex-shrink: 1;
    background-color: rgba(31, 31, 31, 0.2);
  } */
  .zone__photoDescription {
    width: 100%;
  }
  .zone__nextZones {
    display: flex;
    flex-direction: column;
  }
  .zone__nextCards {
    width: 100%;
    border-top: 1px solid rgba(31, 31, 31, 0.2);
  }
  .zone__nextCard {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 34px;
    padding: 48px 0px 24px;
    border-right: none;
    cursor: pointer;
    border-top: 1px solid rgba(31, 31, 31, 0.2);
    justify-content: space-between;
  }
  /* .zone__nextCard:last {
      
      } */
  /* .zone__nextCard:last-child {
    border-top: none;
  } */
  .zone__nextCardTitle {
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
    width: 300px;
    /* height: 80px; */
    text-wrap: wrap;
  }

  .zone__nextCardImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @keyframes slidingUpBtns {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
